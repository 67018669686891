.react-select {
    &__control {
        &--is-focused {
            border-color: $primary-color !important;
            box-shadow: 0 0 0 1px $primary-color !important;
        }
    }

    &__input,
    &__option,
    &__placeholder {
        font-size: .875rem;
    }

    &__option {
        font-size: .875rem;
        &--is-focused {
            background-color: rgba($primary-color, 0.4);
            color: darken($primary-color, 30%) !important;
        }

        &--is-selected {
            background-color: $primary-color !important;
            color: #fff !important;
        }
    }
    &__clear-indicator {
        svg path {
            fill: red;
        }
    }
    &__dropdown-indicator {
        svg path {
            fill: #999;
        }
    }

}
.css-1s2u09g-control{
    border-color: rgba(229, 231, 235, var(--tw-border-opacity)) !important;
}
.css-1okebmr-indicatorSeparator{
    opacity: 0;
}