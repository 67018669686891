.loader {
  border: 2px solid transparent;
  border-top: 2px solid $primary-color;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin .9s linear infinite;
  -webkit-animation: spin .9s linear infinite;

  &.small {
    width: 15px;
    height: 15px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.react-loading-skeleton::after {
  top: 1px;
}

.spinner {
  width: 44px;
  padding: 8px;
  background: #004956;
  aspect-ratio: 1;
  border-radius: 50%;
  --_m:
    conic-gradient(#0000, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  box-sizing: border-box;
  animation: load 1s linear infinite;
}

@keyframes load {
  to {
    transform: rotate(1turn)
  }
}