.connect-widget {
  display: flex;
  align-items: center;
  justify-content: center;

  &__item {
    display: block;
    width: auto;
    max-width: 150px;
    height: auto;
    position: relative;
    flex: 1 1 0;

    img {
      display: block;
      width: 100%;
      max-height: 50px;
      object-fit: contain;
    }
  }

  &__sep {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      display: block;
      width: 30px;
      height: 30px;
      margin: 0 20px;
    }

    &:before, &:after {
      content: '';
      display: inline-block;
      flex: auto;
      width: 50px;
      height: 1px;
      background: transparent;
      border-bottom: 2px dashed #baf3e6;
    }

    &:before {
      margin-right: 20px;
    }

    &:after {
      margin-left: 20px;
    }
  }

  @media screen and(max-width: 767px) {
    &__sep {
      img {
        width: 20px;
        height: 20px;
        margin: 0 10px;
      }
      &:before, &:after {
        width: 25px;
      }

      &:before {
        margin-right: 10px;
      }
      &:after {
        margin-left: 10px;
      }

    }
  }
}