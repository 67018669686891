@font-face {
  font-family: 'sallaicons';
  src:  url('fonts/sallaicons.eot?dbgqres');
  src:  url('fonts/sallaicons.eot?dbgqres#iefix') format('embedded-opentype'),
    url('fonts/sallaicons.ttf?dbgqres') format('truetype'),
    url('fonts/sallaicons.woff?dbgqres') format('woff'),
    url('fonts/sallaicons.svg?dbgqres#sallaicons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="sicon-"], [class*=" sicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sallaicons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sicon-ellipsis-vertical-filled:before {
  content: "\e9a4";
}
.sicon-ellipsis-filled:before {
  content: "\e9a5";
}
.sicon-d-rotate:before {
  content: "\e9a0";
}
.sicon-tiktok:before {
  content: "\e9a3";
}
.sicon-undo:before {
  content: "\e975";
}
.sicon-underline:before {
  content: "\e976";
}
.sicon-ul:before {
  content: "\e97a";
}
.sicon-table:before {
  content: "\e97b";
}
.sicon-strike:before {
  content: "\e97d";
}
.sicon-remove:before {
  content: "\e97e";
}
.sicon-redo:before {
  content: "\e982";
}
.sicon-quote:before {
  content: "\e983";
}
.sicon-paragraph:before {
  content: "\e984";
}
.sicon-ol:before {
  content: "\e985";
}
.sicon-mention:before {
  content: "\e986";
}
.sicon-link1:before {
  content: "\e987";
}
.sicon-italic:before {
  content: "\e988";
}
.sicon-image1:before {
  content: "\e989";
}
.sicon-hr:before {
  content: "\e98a";
}
.sicon-delete_table:before {
  content: "\e98c";
}
.sicon-delete_row:before {
  content: "\e98d";
}
.sicon-delete_col:before {
  content: "\e98e";
}
.sicon-combine_cells:before {
  content: "\e98f";
}
.sicon-code:before {
  content: "\e990";
}
.sicon-checklist:before {
  content: "\e992";
}
.sicon-bold:before {
  content: "\e993";
}
.sicon-add_row_before:before {
  content: "\e997";
}
.sicon-add_row_after:before {
  content: "\e99a";
}
.sicon-add_col_before:before {
  content: "\e99b";
}
.sicon-add_col_after:before {
  content: "\e99c";
}
.sicon-lang:before {
  content: "\e96f";
}
.sicon-cart2:before {
  content: "\e900";
}
.sicon-paper-plane-o:before {
  content: "\e901";
}
.sicon-check-circle2:before {
  content: "\e902";
}
.sicon-facebook2:before {
  content: "\e903";
}
.sicon-instagram2:before {
  content: "\e904";
}
.sicon-shopping-bag2:before {
  content: "\e905";
}
.sicon-trash-2:before {
  content: "\e906";
}
.sicon-twitter2:before {
  content: "\e907";
}
.sicon-activity:before {
  content: "\e908";
}
.sicon-add-circle:before {
  content: "\e909";
}
.sicon-add-square:before {
  content: "\e90a";
}
.sicon-add:before {
  content: "\e90b";
}
.sicon-address-book:before {
  content: "\e90c";
}
.sicon-airplane-mode-off:before {
  content: "\e90d";
}
.sicon-airplane-mode:before {
  content: "\e90e";
}
.sicon-airplane:before {
  content: "\e90f";
}
.sicon-airplay-audio:before {
  content: "\e910";
}
.sicon-airplay-screen:before {
  content: "\e911";
}
.sicon-youtube2:before {
  content: "\e912";
}
.sicon-alarm-add:before {
  content: "\e913";
}
.sicon-alarm-cancel:before {
  content: "\e914";
}
.sicon-alarm-check:before {
  content: "\e915";
}
.sicon-whatsapp2:before {
  content: "\e916";
}
.sicon-alarm-snooze:before {
  content: "\e917";
}
.sicon-alarm:before {
  content: "\e918";
}
.sicon-album-audio:before {
  content: "\e919";
}
.sicon-album-music:before {
  content: "\e91a";
}
.sicon-album-photo:before {
  content: "\e91b";
}
.sicon-album-portrait:before {
  content: "\e91c";
}
.sicon-album-time:before {
  content: "\e91d";
}
.sicon-alert-engine:before {
  content: "\e91e";
}
.sicon-alert:before {
  content: "\e91f";
}
.sicon-add-to-cart:before {
  content: "\e920";
}
.sicon-android:before {
  content: "\e921";
}
.sicon-align-center:before {
  content: "\e922";
}
.sicon-apple:before {
  content: "\e923";
}
.sicon-audio-wave:before {
  content: "\e924";
}
.sicon-align-justify:before {
  content: "\e925";
}
.sicon-align-left:before {
  content: "\e926";
}
.sicon-align-right:before {
  content: "\e927";
}
.sicon-bank-building:before {
  content: "\e928";
}
.sicon-ambulance:before {
  content: "\e929";
}
.sicon-calculator:before {
  content: "\e92a";
}
.sicon-anchor:before {
  content: "\e92b";
}
.sicon-android-phone:before {
  content: "\e92c";
}
.sicon-android-tablet:before {
  content: "\e92d";
}
.sicon-cash-payment:before {
  content: "\e92e";
}
.sicon-debit-card-back:before {
  content: "\e92f";
}
.sicon-delivery-parcel2:before {
  content: "\e930";
}
.sicon-discount-calculator:before {
  content: "\e931";
}
.sicon-discount-coupon:before {
  content: "\e932";
}
.sicon-dollar-cash:before {
  content: "\e933";
}
.sicon-dollar-coin-stack:before {
  content: "\e934";
}
.sicon-dollar-money:before {
  content: "\e935";
}
.sicon-donation:before {
  content: "\e936";
}
.sicon-facebook:before {
  content: "\e937";
}
.sicon-free-sign:before {
  content: "\e938";
}
.sicon-fresh-arrival:before {
  content: "\e939";
}
.sicon-archive-download:before {
  content: "\e93a";
}
.sicon-archive-upload:before {
  content: "\e93b";
}
.sicon-archive:before {
  content: "\e93c";
}
.sicon-full-wallet:before {
  content: "\e93d";
}
.sicon-arrow-diverge:before {
  content: "\e93e";
}
.sicon-arrow-down-left:before {
  content: "\e93f";
}
.sicon-arrow-down-right:before {
  content: "\e940";
}
.sicon-arrow-down:before {
  content: "\e941";
}
.sicon-arrow-expand:before {
  content: "\e942";
}
.sicon-arrow-left:before {
  content: "\e943";
}
.sicon-arrow-merge:before {
  content: "\e944";
}
.sicon-arrow-right:before {
  content: "\e945";
}
.sicon-arrow-shrink:before {
  content: "\e946";
}
.sicon-arrow-swap:before {
  content: "\e947";
}
.sicon-arrow-up-left:before {
  content: "\e948";
}
.sicon-arrow-up-right:before {
  content: "\e949";
}
.sicon-arrow-up:before {
  content: "\e94a";
}
.sicon-art-palette:before {
  content: "\e94b";
}
.sicon-gift-card:before {
  content: "\e94c";
}
.sicon-gift-sharing:before {
  content: "\e94d";
}
.sicon-gold-badge:before {
  content: "\e94e";
}
.sicon-instagram:before {
  content: "\e94f";
}
.sicon-at:before {
  content: "\e950";
}
.sicon-atm:before {
  content: "\e951";
}
.sicon-packed-box:before {
  content: "\e952";
}
.sicon-paper-plane:before {
  content: "\e953";
}
.sicon-paypal:before {
  content: "\e954";
}
.sicon-award-academy:before {
  content: "\e955";
}
.sicon-award-ribbon:before {
  content: "\e956";
}
.sicon-polo-shirt:before {
  content: "\e957";
}
.sicon-round-neck-t-shirt:before {
  content: "\e958";
}
.sicon-sales-presentation:before {
  content: "\e959";
}
.sicon-secure-credit-card-2:before {
  content: "\e95a";
}
.sicon-sending-email:before {
  content: "\e95b";
}
.sicon-shirt:before {
  content: "\e95c";
}
.sicon-snapchat:before {
  content: "\e95d";
}
.sicon-back:before {
  content: "\e95e";
}
.sicon-store:before {
  content: "\e95f";
}
.sicon-trophy:before {
  content: "\e960";
}
.sicon-badge-alt:before {
  content: "\e961";
}
.sicon-badge-ID:before {
  content: "\e962";
}
.sicon-badge:before {
  content: "\e963";
}
.sicon-twitter:before {
  content: "\e964";
}
.sicon-bag-bitcoin:before {
  content: "\e965";
}
.sicon-bag-dollar:before {
  content: "\e966";
}
.sicon-whatsapp:before {
  content: "\e967";
}
.sicon-youtube:before {
  content: "\e968";
}
.sicon-bag-money:before {
  content: "\e969";
}
.sicon-cart:before {
  content: "\e96a";
}
.sicon-keyboard_arrow_right:before {
  content: "\e96b";
}
.sicon-keyboard_arrow_up:before {
  content: "\e96c";
}
.sicon-keyboard_arrow_down:before {
  content: "\e96d";
}
.sicon-keyboard_arrow_left:before {
  content: "\e96e";
}
.sicon-trophy-2:before {
  content: "\e970";
}
.sicon-printer2:before {
  content: "\e971";
}
.sicon-star2:before {
  content: "\e972";
}
.sicon-star-o:before {
  content: "\e973";
}
.sicon-paper-send:before {
  content: "\e974";
}
.sicon-bank:before {
  content: "\e977";
}
.sicon-banknote-bitcoin:before {
  content: "\e978";
}
.sicon-banknote-dollar:before {
  content: "\e979";
}
.sicon-banknote-off:before {
  content: "\e97c";
}
.sicon-banknote:before {
  content: "\e97f";
}
.sicon-barcode-scan:before {
  content: "\e980";
}
.sicon-barcode:before {
  content: "\e981";
}
.sicon-basket:before {
  content: "\e98b";
}
.sicon-battery-alert:before {
  content: "\e991";
}
.sicon-battery-charge:before {
  content: "\e994";
}
.sicon-battery-empty:before {
  content: "\e995";
}
.sicon-battery-full:before {
  content: "\e996";
}
.sicon-battery-low:before {
  content: "\e998";
}
.sicon-battery-off:before {
  content: "\e999";
}
.sicon-beard:before {
  content: "\e9a1";
}
.sicon-bed-single:before {
  content: "\e9a2";
}
.sicon-bell-add:before {
  content: "\e9ab";
}
.sicon-bell-off:before {
  content: "\e9ac";
}
.sicon-bell-ring:before {
  content: "\e9ad";
}
.sicon-bell-snooze:before {
  content: "\e9ae";
}
.sicon-bell-time:before {
  content: "\e9af";
}
.sicon-bell:before {
  content: "\e9b0";
}
.sicon-bells:before {
  content: "\e9b1";
}
.sicon-binary:before {
  content: "\e9b7";
}
.sicon-binoculars:before {
  content: "\e9b8";
}
.sicon-birdhouse:before {
  content: "\e9bb";
}
.sicon-bitcoin:before {
  content: "\e9bd";
}
.sicon-block:before {
  content: "\e9c3";
}
.sicon-bluetooth:before {
  content: "\e9c7";
}
.sicon-boat:before {
  content: "\e9c9";
}
.sicon-book-open:before {
  content: "\e9d4";
}
.sicon-book-person:before {
  content: "\e9d5";
}
.sicon-book:before {
  content: "\e9d6";
}
.sicon-bookmark-add:before {
  content: "\e9d7";
}
.sicon-bookmark:before {
  content: "\e9d8";
}
.sicon-books-alt:before {
  content: "\e9d9";
}
.sicon-books-pencil:before {
  content: "\e9da";
}
.sicon-books:before {
  content: "\e9db";
}
.sicon-border-all:before {
  content: "\e9df";
}
.sicon-box-bankers:before {
  content: "\e9f4";
}
.sicon-box:before {
  content: "\e9f5";
}
.sicon-braille-hand:before {
  content: "\e9f8";
}
.sicon-braille:before {
  content: "\e9f9";
}
.sicon-brain:before {
  content: "\e9fa";
}
.sicon-branch:before {
  content: "\e9fc";
}
.sicon-briefcase-health:before {
  content: "\e9fe";
}
.sicon-briefcase-metal:before {
  content: "\e9ff";
}
.sicon-briefcase:before {
  content: "\ea01";
}
.sicon-brightness-low:before {
  content: "\ea03";
}
.sicon-browser-alt:before {
  content: "\ea08";
}
.sicon-browser-code-alt:before {
  content: "\ea09";
}
.sicon-browser-code:before {
  content: "\ea0a";
}
.sicon-brush:before {
  content: "\ea0c";
}
.sicon-bucket-drip:before {
  content: "\ea0e";
}
.sicon-bullhorn:before {
  content: "\ea15";
}
.sicon-bus-alt:before {
  content: "\ea1c";
}
.sicon-bus:before {
  content: "\ea1f";
}
.sicon-cake:before {
  content: "\ea25";
}
.sicon-calculator2:before {
  content: "\ea26";
}
.sicon-calendar-15:before {
  content: "\ea27";
}
.sicon-calendar-add:before {
  content: "\ea28";
}
.sicon-calendar-alt:before {
  content: "\ea29";
}
.sicon-calendar-cancel:before {
  content: "\ea2a";
}
.sicon-calendar-check:before {
  content: "\ea2b";
}
.sicon-calendar-date:before {
  content: "\ea2c";
}
.sicon-calendar-dates:before {
  content: "\ea2d";
}
.sicon-calendar-download:before {
  content: "\ea2e";
}
.sicon-calendar-favorite:before {
  content: "\ea2f";
}
.sicon-calendar-heart:before {
  content: "\ea30";
}
.sicon-calendar-next:before {
  content: "\ea31";
}
.sicon-calendar-previous:before {
  content: "\ea32";
}
.sicon-calendar-snooze:before {
  content: "\ea33";
}
.sicon-calendar-time:before {
  content: "\ea34";
}
.sicon-calendar-upload:before {
  content: "\ea35";
}
.sicon-calendar-user:before {
  content: "\ea36";
}
.sicon-calendar:before {
  content: "\ea37";
}
.sicon-camcorder:before {
  content: "\ea38";
}
.sicon-camera-add:before {
  content: "\ea39";
}
.sicon-camera-movie:before {
  content: "\ea3e";
}
.sicon-camera-off:before {
  content: "\ea3f";
}
.sicon-camera:before {
  content: "\ea42";
}
.sicon-cancel-circle:before {
  content: "\ea45";
}
.sicon-cancel-square:before {
  content: "\ea46";
}
.sicon-cancel:before {
  content: "\ea47";
}
.sicon-capitol-building:before {
  content: "\ea4f";
}
.sicon-car-alt-off:before {
  content: "\ea51";
}
.sicon-car-alt:before {
  content: "\ea52";
}
.sicon-car-key:before {
  content: "\ea54";
}
.sicon-caret-down-circle:before {
  content: "\ea60";
}
.sicon-caret-down-double:before {
  content: "\ea61";
}
.sicon-caret-down:before {
  content: "\ea62";
}
.sicon-caret-left-circle:before {
  content: "\ea63";
}
.sicon-caret-left-double:before {
  content: "\ea64";
}
.sicon-caret-left:before {
  content: "\ea65";
}
.sicon-caret-right-circle:before {
  content: "\ea66";
}
.sicon-caret-right-double:before {
  content: "\ea67";
}
.sicon-caret-right:before {
  content: "\ea68";
}
.sicon-caret-up-circle:before {
  content: "\ea69";
}
.sicon-caret-up-double:before {
  content: "\ea6a";
}
.sicon-caret-up:before {
  content: "\ea6b";
}
.sicon-carousel:before {
  content: "\ea6c";
}
.sicon-cart-add:before {
  content: "\ea6e";
}
.sicon-cart-cancel:before {
  content: "\ea6f";
}
.sicon-cart-download:before {
  content: "\ea70";
}
.sicon-cart-minus:before {
  content: "\ea71";
}
.sicon-cart-upload:before {
  content: "\ea72";
}
.sicon-cart22:before {
  content: "\ea73";
}
.sicon-cctv-camera:before {
  content: "\ea7a";
}
.sicon-cell-signal:before {
  content: "\ea7d";
}
.sicon-cellphone-landscape:before {
  content: "\ea7e";
}
.sicon-center:before {
  content: "\ea81";
}
.sicon-chart-bar:before {
  content: "\ea8d";
}
.sicon-chart-pie:before {
  content: "\ea8e";
}
.sicon-chat-alert-alt:before {
  content: "\ea8f";
}
.sicon-chat-alert:before {
  content: "\ea90";
}
.sicon-chat-alt:before {
  content: "\ea91";
}
.sicon-chat-bubbles-alt:before {
  content: "\ea92";
}
.sicon-chat-bubbles:before {
  content: "\ea93";
}
.sicon-chat-conversation-alt:before {
  content: "\ea94";
}
.sicon-chat-conversation:before {
  content: "\ea95";
}
.sicon-chat-message-alt:before {
  content: "\ea96";
}
.sicon-chat-message:before {
  content: "\ea97";
}
.sicon-chat-person-alt:before {
  content: "\ea98";
}
.sicon-chat-person:before {
  content: "\ea99";
}
.sicon-chat:before {
  content: "\ea9a";
}
.sicon-check-circle:before {
  content: "\ea9b";
}
.sicon-check-square:before {
  content: "\ea9c";
}
.sicon-check:before {
  content: "\ea9d";
}
.sicon-checkbox-active:before {
  content: "\ea9e";
}
.sicon-checkbox:before {
  content: "\ea9f";
}
.sicon-chip:before {
  content: "\eaaf";
}
.sicon-chromecast:before {
  content: "\eab3";
}
.sicon-city:before {
  content: "\eab8";
}
.sicon-clapboard:before {
  content: "\eaba";
}
.sicon-clipboard-person:before {
  content: "\eac9";
}
.sicon-clock:before {
  content: "\eace";
}
.sicon-closed-captioning:before {
  content: "\eacf";
}
.sicon-clothes-tag:before {
  content: "\ead1";
}
.sicon-cloud-add:before {
  content: "\ead2";
}
.sicon-cloud-audio:before {
  content: "\ead3";
}
.sicon-cloud-cancel:before {
  content: "\ead4";
}
.sicon-cloud-check:before {
  content: "\ead5";
}
.sicon-cloud-day:before {
  content: "\ead6";
}
.sicon-cloud-download:before {
  content: "\ead7";
}
.sicon-cloud-heart:before {
  content: "\ead8";
}
.sicon-cloud-minus:before {
  content: "\ead9";
}
.sicon-cloud-music:before {
  content: "\eadb";
}
.sicon-cloud-night:before {
  content: "\eadc";
}
.sicon-cloud-off:before {
  content: "\eadd";
}
.sicon-cloud-upload:before {
  content: "\eae0";
}
.sicon-cloud:before {
  content: "\eae1";
}
.sicon-cloudy:before {
  content: "\eae2";
}
.sicon-coffee-takeout:before {
  content: "\eaeb";
}
.sicon-coffee-togo:before {
  content: "\eaec";
}
.sicon-coin-bitcoin:before {
  content: "\eaee";
}
.sicon-coin-dollar:before {
  content: "\eaef";
}
.sicon-command:before {
  content: "\eaf6";
}
.sicon-compare:before {
  content: "\eaf8";
}
.sicon-compass-direction:before {
  content: "\eaf9";
}
.sicon-computer:before {
  content: "\eafd";
}
.sicon-contact-book:before {
  content: "\eb03";
}
.sicon-contact-card:before {
  content: "\eb04";
}
.sicon-content:before {
  content: "\eb05";
}
.sicon-cool:before {
  content: "\eb08";
}
.sicon-cord-alt:before {
  content: "\eb0a";
}
.sicon-crdit-card-alt:before {
  content: "\eb14";
}
.sicon-credit-card:before {
  content: "\eb17";
}
.sicon-crop:before {
  content: "\eb1c";
}
.sicon-crown:before {
  content: "\eb20";
}
.sicon-cup-hot:before {
  content: "\eb24";
}
.sicon-cut:before {
  content: "\eb29";
}
.sicon-dashboard-high:before {
  content: "\eb2c";
}
.sicon-dashboard-low:before {
  content: "\eb2d";
}
.sicon-dashboard:before {
  content: "\eb2e";
}
.sicon-database-add:before {
  content: "\eb2f";
}
.sicon-database-cancel:before {
  content: "\eb30";
}
.sicon-database-lock:before {
  content: "\eb31";
}
.sicon-database-minus:before {
  content: "\eb32";
}
.sicon-database:before {
  content: "\eb33";
}
.sicon-dead-mask:before {
  content: "\eb34";
}
.sicon-delete:before {
  content: "\eb38";
}
.sicon-device-image:before {
  content: "\eb39";
}
.sicon-device-portrait:before {
  content: "\eb3a";
}
.sicon-devices:before {
  content: "\eb3c";
}
.sicon-directional-pad:before {
  content: "\eb51";
}
.sicon-do-not-enter:before {
  content: "\eb57";
}
.sicon-doh:before {
  content: "\eb5e";
}
.sicon-dolley:before {
  content: "\eb60";
}
.sicon-double-tap:before {
  content: "\eb66";
}
.sicon-double-zero-square:before {
  content: "\eb67";
}
.sicon-double-zero:before {
  content: "\eb68";
}
.sicon-download-harddrive:before {
  content: "\eb69";
}
.sicon-download:before {
  content: "\eb6a";
}
.sicon-dress-long-sleeve:before {
  content: "\eb6d";
}
.sicon-ear-off:before {
  content: "\eb84";
}
.sicon-ear:before {
  content: "\eb86";
}
.sicon-earth:before {
  content: "\eb89";
}
.sicon-easel-landscape:before {
  content: "\eb8a";
}
.sicon-easel-portrait:before {
  content: "\eb8b";
}
.sicon-edit:before {
  content: "\eb90";
}
.sicon-eject:before {
  content: "\eb99";
}
.sicon-ellipsis-vertical:before {
  content: "\eb9d";
}
.sicon-ellipsis:before {
  content: "\eb9e";
}
.sicon-empire-state-building:before {
  content: "\eb9f";
}
.sicon-energy-circle:before {
  content: "\eba1";
}
.sicon-energy-square:before {
  content: "\eba3";
}
.sicon-envelope:before {
  content: "\eba5";
}
.sicon-ER-square:before {
  content: "\eba8";
}
.sicon-expand:before {
  content: "\ebad";
}
.sicon-expert:before {
  content: "\ebae";
}
.sicon-explode:before {
  content: "\ebaf";
}
.sicon-exposure-alt:before {
  content: "\ebb0";
}
.sicon-eye-dropper:before {
  content: "\ebb3";
}
.sicon-eye-off:before {
  content: "\ebb4";
}
.sicon-eye:before {
  content: "\ebb6";
}
.sicon-fabric-swatch:before {
  content: "\ebb7";
}
.sicon-face-id:before {
  content: "\ebb8";
}
.sicon-fast-forward:before {
  content: "\ebbc";
}
.sicon-favorite-off:before {
  content: "\ebbf";
}
.sicon-favorite:before {
  content: "\ebc0";
}
.sicon-fax-machine:before {
  content: "\ebc1";
}
.sicon-feather-pen:before {
  content: "\ebc2";
}
.sicon-female:before {
  content: "\ebc3";
}
.sicon-file-archive:before {
  content: "\ebcd";
}
.sicon-file-badge:before {
  content: "\ebcf";
}
.sicon-file-bitcoin:before {
  content: "\ebd0";
}
.sicon-file-cabinet:before {
  content: "\ebd1";
}
.sicon-file-dollar:before {
  content: "\ebd4";
}
.sicon-file-download:before {
  content: "\ebd5";
}
.sicon-file-medicine:before {
  content: "\ebdc";
}
.sicon-file-off:before {
  content: "\ebe1";
}
.sicon-file-partial:before {
  content: "\ebe2";
}
.sicon-file-upload:before {
  content: "\ebec";
}
.sicon-film-reel:before {
  content: "\ebf0";
}
.sicon-film-strip:before {
  content: "\ebf1";
}
.sicon-film:before {
  content: "\ebf2";
}
.sicon-filter:before {
  content: "\ebf3";
}
.sicon-fingerprint:before {
  content: "\ebf4";
}
.sicon-fire:before {
  content: "\ebf7";
}
.sicon-fit:before {
  content: "\ec01";
}
.sicon-fitness-watch:before {
  content: "\ec02";
}
.sicon-flag-wave:before {
  content: "\ec06";
}
.sicon-flag:before {
  content: "\ec07";
}
.sicon-flash:before {
  content: "\ec0b";
}
.sicon-floppy-disk:before {
  content: "\ec0e";
}
.sicon-flower-setting:before {
  content: "\ec10";
}
.sicon-flower-stem:before {
  content: "\ec11";
}
.sicon-folder-add:before {
  content: "\ec17";
}
.sicon-folder-audio:before {
  content: "\ec18";
}
.sicon-folder-cancel:before {
  content: "\ec19";
}
.sicon-folder-check:before {
  content: "\ec1a";
}
.sicon-folder-cloud:before {
  content: "\ec1b";
}
.sicon-folder-code:before {
  content: "\ec1c";
}
.sicon-folder-download:before {
  content: "\ec1d";
}
.sicon-folder-health:before {
  content: "\ec1e";
}
.sicon-folder-image:before {
  content: "\ec1f";
}
.sicon-folder-music:before {
  content: "\ec20";
}
.sicon-folder-open:before {
  content: "\ec21";
}
.sicon-folder-upload:before {
  content: "\ec22";
}
.sicon-folder-user:before {
  content: "\ec23";
}
.sicon-folder:before {
  content: "\ec24";
}
.sicon-folders-image:before {
  content: "\ec25";
}
.sicon-folders:before {
  content: "\ec26";
}
.sicon-fork-knife:before {
  content: "\ec2d";
}
.sicon-forked:before {
  content: "\ec31";
}
.sicon-format-bold:before {
  content: "\ec32";
}
.sicon-format-border-color:before {
  content: "\ec33";
}
.sicon-format-fill:before {
  content: "\ec34";
}
.sicon-format-italic:before {
  content: "\ec35";
}
.sicon-format-shapes:before {
  content: "\ec37";
}
.sicon-format-size:before {
  content: "\ec38";
}
.sicon-format-strikethrough-alt:before {
  content: "\ec39";
}
.sicon-format-strikethrough:before {
  content: "\ec3a";
}
.sicon-format-text-alt:before {
  content: "\ec3b";
}
.sicon-format-text-color:before {
  content: "\ec3c";
}
.sicon-format-text:before {
  content: "\ec3d";
}
.sicon-format-underline:before {
  content: "\ec3e";
}
.sicon-forward:before {
  content: "\ec43";
}
.sicon-frame-image:before {
  content: "\ec48";
}
.sicon-frame-portrait:before {
  content: "\ec49";
}
.sicon-frown:before {
  content: "\ec4f";
}
.sicon-game-controller-alt:before {
  content: "\ec52";
}
.sicon-gameboard:before {
  content: "\ec54";
}
.sicon-gameboy:before {
  content: "\ec55";
}
.sicon-gavel:before {
  content: "\ec5a";
}
.sicon-ghost:before {
  content: "\ec5b";
}
.sicon-gift:before {
  content: "\ec5d";
}
.sicon-gifts:before {
  content: "\ec5e";
}
.sicon-graduation-cap:before {
  content: "\ec6f";
}
.sicon-graph-bar:before {
  content: "\ec71";
}
.sicon-graph-line:before {
  content: "\ec72";
}
.sicon-grid:before {
  content: "\ec76";
}
.sicon-grocery-bag:before {
  content: "\ec7a";
}
.sicon-group-add:before {
  content: "\ec7b";
}
.sicon-group-four:before {
  content: "\ec7c";
}
.sicon-group:before {
  content: "\ec7d";
}
.sicon-hammer-screwdriver:before {
  content: "\ec86";
}
.sicon-hand:before {
  content: "\ec89";
}
.sicon-happy:before {
  content: "\ec8a";
}
.sicon-harddrive:before {
  content: "\ec8c";
}
.sicon-hashtag:before {
  content: "\ec8f";
}
.sicon-HD-square:before {
  content: "\ec92";
}
.sicon-HD:before {
  content: "\ec93";
}
.sicon-headphones:before {
  content: "\ec97";
}
.sicon-headset:before {
  content: "\ec98";
}
.sicon-health-cross:before {
  content: "\ec99";
}
.sicon-heart-arrow:before {
  content: "\ec9a";
}
.sicon-heart-check:before {
  content: "\ec9b";
}
.sicon-heart-money:before {
  content: "\ec9e";
}
.sicon-heart-off:before {
  content: "\ec9f";
}
.sicon-heart-rate:before {
  content: "\eca0";
}
.sicon-heart:before {
  content: "\eca1";
}
.sicon-hearts:before {
  content: "\eca2";
}
.sicon-help:before {
  content: "\eca5";
}
.sicon-hierarchy:before {
  content: "\eca6";
}
.sicon-hiking-pack:before {
  content: "\eca9";
}
.sicon-hiking:before {
  content: "\ecaa";
}
.sicon-hipster:before {
  content: "\ecac";
}
.sicon-history:before {
  content: "\ecad";
}
.sicon-hitchikers-guide:before {
  content: "\ecae";
}
.sicon-hobby-knife:before {
  content: "\ecaf";
}
.sicon-home-heart:before {
  content: "\ecb4";
}
.sicon-home-water:before {
  content: "\ecb6";
}
.sicon-home:before {
  content: "\ecb7";
}
.sicon-house-door:before {
  content: "\ecc4";
}
.sicon-house:before {
  content: "\ecc7";
}
.sicon-image-carousel:before {
  content: "\ecd0";
}
.sicon-image-chat:before {
  content: "\ecd1";
}
.sicon-image-search:before {
  content: "\ecd2";
}
.sicon-image:before {
  content: "\ecd3";
}
.sicon-images:before {
  content: "\ecd4";
}
.sicon-inbox-add:before {
  content: "\ecd5";
}
.sicon-inbox-download:before {
  content: "\ecd6";
}
.sicon-inbox-full:before {
  content: "\ecd7";
}
.sicon-inbox-multi:before {
  content: "\ecd8";
}
.sicon-inbox-music:before {
  content: "\ecd9";
}
.sicon-inbox-upload:before {
  content: "\ecda";
}
.sicon-inbox:before {
  content: "\ecdb";
}
.sicon-indent-decrease:before {
  content: "\ecdc";
}
.sicon-indent:before {
  content: "\ecdd";
}
.sicon-info:before {
  content: "\ecdf";
}
.sicon-information:before {
  content: "\ece0";
}
.sicon-ipad:before {
  content: "\ece3";
}
.sicon-iphone-x:before {
  content: "\ece4";
}
.sicon-iphone:before {
  content: "\ece5";
}
.sicon-ipod:before {
  content: "\ece6";
}
.sicon-journal-pencil:before {
  content: "\ecf0";
}
.sicon-journal:before {
  content: "\ecf1";
}
.sicon-key:before {
  content: "\ecf8";
}
.sicon-keyboard-down:before {
  content: "\ecf9";
}
.sicon-keyboard-up:before {
  content: "\ecfb";
}
.sicon-keyboard:before {
  content: "\ecfc";
}
.sicon-keyhole-alt:before {
  content: "\ecfd";
}
.sicon-keyhole:before {
  content: "\ecfe";
}
.sicon-keypad:before {
  content: "\ed00";
}
.sicon-laptop:before {
  content: "\ed12";
}
.sicon-layout-grid-rearrange:before {
  content: "\ed13";
}
.sicon-layout-grid:before {
  content: "\ed14";
}
.sicon-leaf-angle:before {
  content: "\ed16";
}
.sicon-left:before {
  content: "\ed1c";
}
.sicon-lego:before {
  content: "\ed1d";
}
.sicon-library:before {
  content: "\ed22";
}
.sicon-life-ring:before {
  content: "\ed23";
}
.sicon-lightbulb:before {
  content: "\ed28";
}
.sicon-lightning:before {
  content: "\ed2a";
}
.sicon-link:before {
  content: "\ed2c";
}
.sicon-list-add:before {
  content: "\ed2e";
}
.sicon-list-check:before {
  content: "\ed2f";
}
.sicon-list-numbered:before {
  content: "\ed30";
}
.sicon-list-play:before {
  content: "\ed31";
}
.sicon-list-reorder:before {
  content: "\ed32";
}
.sicon-list:before {
  content: "\ed33";
}
.sicon-location-add:before {
  content: "\ed37";
}
.sicon-location-cancel:before {
  content: "\ed38";
}
.sicon-location-off:before {
  content: "\ed39";
}
.sicon-location-target:before {
  content: "\ed3a";
}
.sicon-location:before {
  content: "\ed3b";
}
.sicon-lock-off:before {
  content: "\ed3c";
}
.sicon-lock:before {
  content: "\ed3d";
}
.sicon-loupe-zoom:before {
  content: "\ed42";
}
.sicon-loupe:before {
  content: "\ed43";
}
.sicon-love-letter:before {
  content: "\ed44";
}
.sicon-luggage-cart:before {
  content: "\ed45";
}
.sicon-macro:before {
  content: "\ed49";
}
.sicon-magazine:before {
  content: "\ed4a";
}
.sicon-magic-wand:before {
  content: "\ed4b";
}
.sicon-magnet:before {
  content: "\ed4c";
}
.sicon-mail-add-alt:before {
  content: "\ed4d";
}
.sicon-mail-add:before {
  content: "\ed4e";
}
.sicon-mail-cascade:before {
  content: "\ed4f";
}
.sicon-mail-download:before {
  content: "\ed50";
}
.sicon-mail-letter:before {
  content: "\ed51";
}
.sicon-mail-multi:before {
  content: "\ed52";
}
.sicon-mail-open:before {
  content: "\ed53";
}
.sicon-mail-search:before {
  content: "\ed54";
}
.sicon-mail-time:before {
  content: "\ed55";
}
.sicon-mail-upload:before {
  content: "\ed56";
}
.sicon-mail:before {
  content: "\ed57";
}
.sicon-mailbox:before {
  content: "\ed58";
}
.sicon-male:before {
  content: "\ed59";
}
.sicon-man-fancy:before {
  content: "\ed5b";
}
.sicon-map-grid:before {
  content: "\ed60";
}
.sicon-map-location:before {
  content: "\ed61";
}
.sicon-map-search:before {
  content: "\ed62";
}
.sicon-map:before {
  content: "\ed63";
}
.sicon-medal:before {
  content: "\ed70";
}
.sicon-media-player:before {
  content: "\ed71";
}
.sicon-megaphone:before {
  content: "\ed76";
}
.sicon-meh:before {
  content: "\ed77";
}
.sicon-memo-money:before {
  content: "\ed7a";
}
.sicon-memo:before {
  content: "\ed7b";
}
.sicon-menu-vertical:before {
  content: "\ed7e";
}
.sicon-menu:before {
  content: "\ed7f";
}
.sicon-mic-alt:before {
  content: "\ed84";
}
.sicon-mic-stand:before {
  content: "\ed87";
}
.sicon-mic:before {
  content: "\ed88";
}
.sicon-minimize:before {
  content: "\ed8e";
}
.sicon-minus-circle:before {
  content: "\ed8f";
}
.sicon-minus-square:before {
  content: "\ed90";
}
.sicon-minus:before {
  content: "\ed91";
}
.sicon-money-card:before {
  content: "\ed96";
}
.sicon-money-chat:before {
  content: "\ed97";
}
.sicon-money-circle:before {
  content: "\ed98";
}
.sicon-money-home:before {
  content: "\ed99";
}
.sicon-moon:before {
  content: "\eda5";
}
.sicon-mouse-buttons:before {
  content: "\edac";
}
.sicon-mug-heart:before {
  content: "\edb0";
}
.sicon-music-cascade:before {
  content: "\edb7";
}
.sicon-music:before {
  content: "\edbb";
}
.sicon-navigation-circle:before {
  content: "\edc1";
}
.sicon-navigation:before {
  content: "\edc2";
}
.sicon-near-me-circle:before {
  content: "\edc3";
}
.sicon-near-me:before {
  content: "\edc4";
}
.sicon-neck-tie:before {
  content: "\edc5";
}
.sicon-newspaper-alt:before {
  content: "\edca";
}
.sicon-newspaper:before {
  content: "\edcb";
}
.sicon-notification:before {
  content: "\edd5";
}
.sicon-office:before {
  content: "\edda";
}
.sicon-padlock-unlock:before {
  content: "\edf0";
}
.sicon-padlock:before {
  content: "\edf1";
}
.sicon-page-add:before {
  content: "\edf2";
}
.sicon-page-alt:before {
  content: "\edf3";
}
.sicon-page-cancel:before {
  content: "\edf4";
}
.sicon-page-check:before {
  content: "\edf5";
}
.sicon-page-content:before {
  content: "\edf6";
}
.sicon-page-minus:before {
  content: "\edf7";
}
.sicon-page-search-alt:before {
  content: "\edf8";
}
.sicon-page-search:before {
  content: "\edf9";
}
.sicon-page:before {
  content: "\edfa";
}
.sicon-pages-insert:before {
  content: "\edfc";
}
.sicon-pages:before {
  content: "\edfd";
}
.sicon-paper-airplane:before {
  content: "\ee0a";
}
.sicon-paperclip:before {
  content: "\ee0c";
}
.sicon-parking:before {
  content: "\ee0f";
}
.sicon-partly-cloudy:before {
  content: "\ee11";
}
.sicon-party-bell:before {
  content: "\ee12";
}
.sicon-party-horn:before {
  content: "\ee13";
}
.sicon-passport:before {
  content: "\ee14";
}
.sicon-pause-circle:before {
  content: "\ee16";
}
.sicon-pause:before {
  content: "\ee17";
}
.sicon-pen-nib:before {
  content: "\ee21";
}
.sicon-pen:before {
  content: "\ee23";
}
.sicon-pencil-off:before {
  content: "\ee24";
}
.sicon-pencil-paintbrush:before {
  content: "\ee26";
}
.sicon-pencil-ruler:before {
  content: "\ee27";
}
.sicon-pencil:before {
  content: "\ee29";
}
.sicon-percentage-chat:before {
  content: "\ee2f";
}
.sicon-percentage:before {
  content: "\ee30";
}
.sicon-person-bowtie-suspenders:before {
  content: "\ee31";
}
.sicon-phone-pencil:before {
  content: "\ee3c";
}
.sicon-phone-rotary:before {
  content: "\ee3f";
}
.sicon-phone-talking:before {
  content: "\ee40";
}
.sicon-phone:before {
  content: "\ee41";
}
.sicon-photo-flower:before {
  content: "\ee42";
}
.sicon-photo-library:before {
  content: "\ee43";
}
.sicon-photo-portrait:before {
  content: "\ee44";
}
.sicon-photo-search:before {
  content: "\ee45";
}
.sicon-photo:before {
  content: "\ee46";
}
.sicon-photos:before {
  content: "\ee47";
}
.sicon-pin:before {
  content: "\ee54";
}
.sicon-plane-arrival:before {
  content: "\ee5c";
}
.sicon-plane-departure:before {
  content: "\ee5d";
}
.sicon-play-circle:before {
  content: "\ee61";
}
.sicon-play-library:before {
  content: "\ee62";
}
.sicon-play:before {
  content: "\ee63";
}
.sicon-playlist:before {
  content: "\ee64";
}
.sicon-plug-electric:before {
  content: "\ee65";
}
.sicon-plug-sun:before {
  content: "\ee66";
}
.sicon-plug:before {
  content: "\ee68";
}
.sicon-podcast:before {
  content: "\ee6e";
}
.sicon-point-down:before {
  content: "\ee71";
}
.sicon-point-left:before {
  content: "\ee72";
}
.sicon-point-right:before {
  content: "\ee73";
}
.sicon-point-up:before {
  content: "\ee74";
}
.sicon-poll:before {
  content: "\ee7a";
}
.sicon-portfolio:before {
  content: "\ee8b";
}
.sicon-portrait-carousel:before {
  content: "\ee8d";
}
.sicon-portrait-library:before {
  content: "\ee8e";
}
.sicon-portrait:before {
  content: "\ee8f";
}
.sicon-portraits:before {
  content: "\ee90";
}
.sicon-power-circle:before {
  content: "\ee98";
}
.sicon-power:before {
  content: "\ee9a";
}
.sicon-prescription-bottle:before {
  content: "\ee9b";
}
.sicon-presentation-alt:before {
  content: "\ee9e";
}
.sicon-printer:before {
  content: "\eea1";
}
.sicon-puzzle:before {
  content: "\eea8";
}
.sicon-quote-close-alt:before {
  content: "\eeaa";
}
.sicon-quote-close:before {
  content: "\eeab";
}
.sicon-quote-open-alt:before {
  content: "\eeac";
}
.sicon-quote-open:before {
  content: "\eead";
}
.sicon-receipt-money:before {
  content: "\eebf";
}
.sicon-receipt:before {
  content: "\eec0";
}
.sicon-recycle:before {
  content: "\eec3";
}
.sicon-redeye:before {
  content: "\eec4";
}
.sicon-refund:before {
  content: "\eec6";
}
.sicon-register:before {
  content: "\eec7";
}
.sicon-reply-all:before {
  content: "\eecb";
}
.sicon-reply:before {
  content: "\eecc";
}
.sicon-rewind:before {
  content: "\eece";
}
.sicon-right:before {
  content: "\eed1";
}
.sicon-rocket:before {
  content: "\eed6";
}
.sicon-room-card:before {
  content: "\eed9";
}
.sicon-rotate:before {
  content: "\eeda";
}
.sicon-rotation-lock:before {
  content: "\eedb";
}
.sicon-rotation-unlock:before {
  content: "\eedc";
}
.sicon-route-highway:before {
  content: "\eedd";
}
.sicon-rss:before {
  content: "\eee0";
}
.sicon-ruler-square:before {
  content: "\eee4";
}
.sicon-ruler:before {
  content: "\eee5";
}
.sicon-running:before {
  content: "\eee6";
}
.sicon-school-bell:before {
  content: "\eef6";
}
.sicon-schoolbus:before {
  content: "\eef8";
}
.sicon-science:before {
  content: "\eef9";
}
.sicon-screw-flathead:before {
  content: "\eefc";
}
.sicon-screw-phillips:before {
  content: "\eefd";
}
.sicon-screw-star:before {
  content: "\eeff";
}
.sicon-script:before {
  content: "\ef04";
}
.sicon-sd-card:before {
  content: "\ef07";
}
.sicon-search:before {
  content: "\ef09";
}
.sicon-season-winter:before {
  content: "\ef0f";
}
.sicon-security-camera:before {
  content: "\ef16";
}
.sicon-send-down:before {
  content: "\ef17";
}
.sicon-send-forward:before {
  content: "\ef18";
}
.sicon-send-in:before {
  content: "\ef19";
}
.sicon-send-mail:before {
  content: "\ef1a";
}
.sicon-send-out:before {
  content: "\ef1b";
}
.sicon-send-to-device:before {
  content: "\ef1c";
}
.sicon-send-up:before {
  content: "\ef1d";
}
.sicon-send:before {
  content: "\ef1e";
}
.sicon-settings:before {
  content: "\ef1f";
}
.sicon-shake:before {
  content: "\ef22";
}
.sicon-share-alt:before {
  content: "\ef25";
}
.sicon-share:before {
  content: "\ef26";
}
.sicon-shield-alert:before {
  content: "\ef29";
}
.sicon-shield-alt:before {
  content: "\ef2a";
}
.sicon-shield-check:before {
  content: "\ef2b";
}
.sicon-ship-wheel:before {
  content: "\ef2f";
}
.sicon-ship:before {
  content: "\ef30";
}
.sicon-shipping-fast:before {
  content: "\ef31";
}
.sicon-shipping:before {
  content: "\ef32";
}
.sicon-shirt-button-down:before {
  content: "\ef33";
}
.sicon-shirt-tie:before {
  content: "\ef34";
}
.sicon-shopping-bag:before {
  content: "\ef36";
}
.sicon-shopping-basket-alt:before {
  content: "\ef37";
}
.sicon-shopping-basket:before {
  content: "\ef38";
}
.sicon-shopping:before {
  content: "\ef39";
}
.sicon-shredder:before {
  content: "\ef3d";
}
.sicon-shuttle:before {
  content: "\ef41";
}
.sicon-signal-range:before {
  content: "\ef45";
}
.sicon-signs-alt:before {
  content: "\ef46";
}
.sicon-sim-card-alert:before {
  content: "\ef48";
}
.sicon-skip-backward:before {
  content: "\ef59";
}
.sicon-skip-forward:before {
  content: "\ef5a";
}
.sicon-skull-crossbones:before {
  content: "\ef5d";
}
.sicon-skull:before {
  content: "\ef5e";
}
.sicon-sleep:before {
  content: "\ef60";
}
.sicon-smile:before {
  content: "\ef63";
}
.sicon-speaker:before {
  content: "\ef80";
}
.sicon-special-alert:before {
  content: "\ef81";
}
.sicon-special-check:before {
  content: "\ef82";
}
.sicon-special-discount:before {
  content: "\ef83";
}
.sicon-special-money:before {
  content: "\ef84";
}
.sicon-spinner:before {
  content: "\ef8f";
}
.sicon-spy:before {
  content: "\ef9b";
}
.sicon-stack:before {
  content: "\ef9e";
}
.sicon-stair-car:before {
  content: "\ef9f";
}
.sicon-stamp-inset:before {
  content: "\efa2";
}
.sicon-stanp-portrait:before {
  content: "\efa4";
}
.sicon-star:before {
  content: "\efa8";
}
.sicon-steps:before {
  content: "\efae";
}
.sicon-stocks-down:before {
  content: "\efb2";
}
.sicon-stocks-up:before {
  content: "\efb3";
}
.sicon-stopsign-alert:before {
  content: "\efb7";
}
.sicon-stopwatch-off:before {
  content: "\efb8";
}
.sicon-stopwatch:before {
  content: "\efb9";
}
.sicon-store-alt:before {
  content: "\efba";
}
.sicon-store2:before {
  content: "\efbb";
}
.sicon-storm:before {
  content: "\efbc";
}
.sicon-suitcase:before {
  content: "\efc6";
}
.sicon-sun:before {
  content: "\efc8";
}
.sicon-sunglasses:before {
  content: "\efc9";
}
.sicon-survey:before {
  content: "\efd0";
}
.sicon-swap-fill:before {
  content: "\efd2";
}
.sicon-swap-stroke:before {
  content: "\efd3";
}
.sicon-swatchbook:before {
  content: "\efd4";
}
.sicon-swipe-left:before {
  content: "\efd6";
}
.sicon-swipe-right:before {
  content: "\efd7";
}
.sicon-switch-flip:before {
  content: "\efd8";
}
.sicon-sync:before {
  content: "\efdf";
}
.sicon-t-shirt-long-sleeve:before {
  content: "\efe2";
}
.sicon-t-shirt:before {
  content: "\efe3";
}
.sicon-tag-money:before {
  content: "\efe9";
}
.sicon-tag-special:before {
  content: "\efea";
}
.sicon-tag:before {
  content: "\efeb";
}
.sicon-tap:before {
  content: "\efef";
}
.sicon-target:before {
  content: "\eff3";
}
.sicon-team:before {
  content: "\eff5";
}
.sicon-terminal:before {
  content: "\f002";
}
.sicon-theater-masks:before {
  content: "\f004";
}
.sicon-three-square:before {
  content: "\f008";
}
.sicon-thumbdrive:before {
  content: "\f00a";
}
.sicon-thumbs-down:before {
  content: "\f00b";
}
.sicon-thumbs-up:before {
  content: "\f00c";
}
.sicon-thumbtack:before {
  content: "\f00d";
}
.sicon-ticket-movie:before {
  content: "\f00f";
}
.sicon-ticket:before {
  content: "\f010";
}
.sicon-time:before {
  content: "\f014";
}
.sicon-timer:before {
  content: "\f016";
}
.sicon-toggle-off:before {
  content: "\f01b";
}
.sicon-toggles:before {
  content: "\f01d";
}
.sicon-toolbox:before {
  content: "\f027";
}
.sicon-tornado:before {
  content: "\f02c";
}
.sicon-touch-type:before {
  content: "\f02e";
}
.sicon-trail-map:before {
  content: "\f036";
}
.sicon-train:before {
  content: "\f03b";
}
.sicon-transfer-alt:before {
  content: "\f03e";
}
.sicon-transfer:before {
  content: "\f03f";
}
.sicon-trash-cancel:before {
  content: "\f040";
}
.sicon-trash:before {
  content: "\f041";
}
.sicon-treasure-chest:before {
  content: "\f043";
}
.sicon-trend-down:before {
  content: "\f048";
}
.sicon-trend-up:before {
  content: "\f049";
}
.sicon-triangle-measure:before {
  content: "\f04a";
}
.sicon-trophy2:before {
  content: "\f04c";
}
.sicon-tty:before {
  content: "\f04e";
}
.sicon-tulip-pot:before {
  content: "\f04f";
}
.sicon-tune-alt:before {
  content: "\f050";
}
.sicon-tune:before {
  content: "\f051";
}
.sicon-turkey:before {
  content: "\f052";
}
.sicon-turret:before {
  content: "\f055";
}
.sicon-tv:before {
  content: "\f058";
}
.sicon-type-square:before {
  content: "\f060";
}
.sicon-typewriter:before {
  content: "\f061";
}
.sicon-typography:before {
  content: "\f062";
}
.sicon-unicorn:before {
  content: "\f067";
}
.sicon-universal-access-alt:before {
  content: "\f069";
}
.sicon-universal-access:before {
  content: "\f06b";
}
.sicon-unlock:before {
  content: "\f06c";
}
.sicon-user-add:before {
  content: "\f06f";
}
.sicon-user-cancel:before {
  content: "\f070";
}
.sicon-user-check:before {
  content: "\f071";
}
.sicon-user-circle:before {
  content: "\f072";
}
.sicon-user-heart:before {
  content: "\f073";
}
.sicon-user-list:before {
  content: "\f074";
}
.sicon-user-minus:before {
  content: "\f075";
}
.sicon-user-off:before {
  content: "\f076";
}
.sicon-user-square:before {
  content: "\f077";
}
.sicon-user:before {
  content: "\f078";
}
.sicon-users:before {
  content: "\f079";
}
.sicon-village:before {
  content: "\f084";
}
.sicon-vision:before {
  content: "\f086";
}
.sicon-volume-high:before {
  content: "\f08a";
}
.sicon-volume-low:before {
  content: "\f08b";
}
.sicon-volume-mute:before {
  content: "\f08c";
}
.sicon-volume-off:before {
  content: "\f08d";
}
.sicon-volume:before {
  content: "\f08e";
}
.sicon-waffle:before {
  content: "\f090";
}
.sicon-walkie-talkie:before {
  content: "\f091";
}
.sicon-wallet:before {
  content: "\f094";
}
.sicon-warming-cover:before {
  content: "\f096";
}
.sicon-warning:before {
  content: "\f097";
}
.sicon-watch-apple:before {
  content: "\f09a";
}
.sicon-watch-smart:before {
  content: "\f09b";
}
.sicon-watch:before {
  content: "\f09c";
}
.sicon-wave:before {
  content: "\f0a1";
}
.sicon-wheelchair-alt:before {
  content: "\f0a9";
}
.sicon-wheelchair:before {
  content: "\f0aa";
}
.sicon-whistle:before {
  content: "\f0ad";
}
.sicon-wifi:before {
  content: "\f0af";
}
.sicon-wind:before {
  content: "\f0b1";
}
.sicon-window-layout:before {
  content: "\f0b3";
}
.sicon-winners-dias:before {
  content: "\f0b9";
}
.sicon-woman-man:before {
  content: "\f0c1";
}
.sicon-women:before {
  content: "\f0c2";
}
.sicon-world:before {
  content: "\f0c3";
}
.sicon-wrench-pencil:before {
  content: "\f0c6";
}
.sicon-wrench:before {
  content: "\f0c8";
}
.sicon-wrenches:before {
  content: "\f0c9";
}
.sicon-writing:before {
  content: "\f0ca";
}
.sicon-zip-archive:before {
  content: "\f0d3";
}
.sicon-zoom-in:before {
  content: "\f0d8";
}
.sicon-zoom-out:before {
  content: "\f0d9";
}
.sicon-sort-asc:before {
  content: "\e99d";
}
.sicon-sort-desc:before {
  content: "\e99e";
}
.sicon-sort:before {
  content: "\e99f";
}
