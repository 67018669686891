.nav {
    margin-bottom: 15px;
    text-align: center;
    .dot {
        cursor: pointer;
        &:before {
            content: '';
            width: 16px;
            height: 7px;
            background: #C6C7CE;
            border: 1px solid #FFFFFF;
            border-radius: 17px;
            display: inline-block;
            margin: 0 2px;
            transition: width 0.3s ease-in-out;
            -webkit-transition: width 0.3s ease-in-out;
            -moz-transition: width 0.3s ease-in-out;
            -ms-transition: width 0.3s ease-in-out;
            -o-transition: width 0.3s ease-in-out;
        }
        &.active {
            &:before {
                width: 32px;
                background: $primary-color;
            }
        }
    }
}