@import 'fonts';
@import 'transitions';
@import 'variables';
@import 'wizardNav';
@import 'reactSelect';
@import 'loader';
@import 'connect-widget';

body {
    background-color: #F8F8F8;
    direction: rtl;
    overflow-x: hidden;
}

.scrollable::-webkit-scrollbar {
    width: 4px;
}


.scrollable::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    -ms-border-radius: 50px;
    -o-border-radius: 50px;
}

